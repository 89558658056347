.calendar-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  color: var(--text-color);
}

.details-section {
  margin-top: 20px;
  padding: 10px;
  color: var(--accent-color);
  border-radius: 8px;
  padding-left: 30px;
  width: 100%;
  max-width: 600px;
  background-color: var(--foreground-color);
}

.subhead {
  max-width: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.details {
  text-align: left;
}

.details h3 {
  margin-bottom: 10px;
}

/* Calendar.css */

/* Style the calendar container */
.react-calendar {
  background-color: var(--foreground-color); /* Dark background from the site */
  color: var(--text-color); /* Text color matching the site's text */
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

/* Tile (day button) styling */
.react-calendar__tile {
  background-color: var(--button-color); /* Foreground color for tiles */
  color: var(--button-text-color); /* Text color for tiles */
  transition: background-color 0.3s ease;
}

/* Hover effect for tiles */
.react-calendar__tile:hover {
  background-color: var(--accent-color); /* Button color on hover */
  color: var(--button-text-color); /* Ensure text remains readable */
}

/* Selected date styling */
.react-calendar__tile--active {
  background-color: var(--accent-color); /* Accent color for active date */
  color: var(--text-color); /* Text color for active date */
}

/* Navigation (prev/next buttons) styling */
.react-calendar__navigation button {
  background-color: var(--button-color); /* Button background */
  color: var(--button-text-color); /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

/* Hover effect for navigation buttons */
.react-calendar__navigation button:hover {
  background-color: var(--accent-color); /* Accent color on hover */
  color: var(--text-color); /* Text color on hover */
}
