.flashcard-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the entire image fits without cropping */
  border-radius: 20px;
  padding: 10px; /* Optional padding for a cleaner look */
}

.flashcards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  margin: 20px;
}

.progress {
  background-color: var(--foreground-color);
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 20px;
}

.flashcard {
  width: 40%;
  height: 55%;
  perspective: 1000px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .flashcard {
    width: 90%;
    height: 50%;
    margin-bottom: 10px;
  }

  .action-buttons button {
    padding: 15px 30px;
    font-size: 18px;
  }
}

.flashcard-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flashcard.flipped .flashcard-inner {
  transform: rotateY(180deg);
}

.flashcard-front,
.flashcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background-color: var(--foreground-color);
  border-radius: 10px;
  text-align: center;
}

.flashcard-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: 16px; /* Adjust size as necessary */
}

.flashcard-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px; /* Space between the title and other info */
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.action-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bad-button {
  color: var(--accent-color);
  background-color: #ff6b6b;
}

.good-button {
  color: var(--accent-color);
  background-color: #ffcc00;
}

.great-button {
  color: var(--accent-color);
  background-color: #4caf50;
}

.reset-button {
  color: var(--accent-color);
  background-color: var(--button-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.settings-button {
  color: var(--text-color);

  position: fixed;
  top: 70px;
  right: 10px;
  padding: 10px 10px;
  border: none;
  width: 48px; /* Ensures a square shape */
  height: 48px; /* Matches the width */
  background-color: var(--accent-color);
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000000000; /* Ensure it is above other content */
  font-size: 16px;
}

.settings-button:hover {
  background-color: var(--accent-color);
}

/* Sliding Modal */
.settings-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px; /* Set width of the sliding modal */
  height: 100vh;
  background-color: var(--foreground-color);
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.3s ease-in-out; /* Smooth slide in */
  z-index: 1000; /* Ensure it is above other content */
}

.settings-modal.show {
  transform: translateX(0); /* Slide in from the right */
}

.settings-modal h3 {
  margin-top: 0;
  font-size: 24px;
  color: var(--accent-color);
  padding-bottom: 10px;
}

.settings-modal label {
  display: block;
  margin: 10px 0;
  font-size: 18px;
  color: var(--accent-color);
}

.settings-modal input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.close-settings {
  display: inline-block;
  padding: 10px 15px;
  background-color: var(--button-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.popup-banner {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--foreground-color);
  color: var(--accent-color);
  max-height: 80vh;
  width: 70%;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}

.popup-banner .blurb {
  margin: 0;
  font-size: 16px;
  color: var(--accent-color);
}

.close-banner {
  background-color: transparent;
  color: var(--accent-color);
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 20px;
}
