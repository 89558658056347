.pagecontainer {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60);
  width: 100%;
  overflow-x: hidden;
  padding: 0 20px; /* Some padding for desktop and mobile */
}

.text {
  z-index: 1;
  padding: 10px; /* Padding around the text */
}

.scrolling-background1,
.scrolling-background2 {
  position: absolute;
  background-image: url("../../../public/homepageBackground.webp");
  width: 200%;
  height: 100%;
  background-size: cover;
  background-position: top left;
  opacity: 0.5;
  z-index: 0;
}

.scrolling-background1 {
  animation: scroll1 200s linear infinite;
}

.scrolling-background2 {
  animation: scroll2 200s linear infinite;
}

@keyframes scroll1 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  50.01% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scroll2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.w3-button.button {
  max-width: 300px; /* Limit size on large screens */
  margin: 0 auto; /* Center the button horizontally */
  width: 100%; /* Full width on mobile */
  background-color: var(--button-color); /* Main button background color */
  color: var(--button-text-color); /* Text color */
  border: none;
  padding: 15px 20px; /* Make the buttons large and clickable */
  font-size: 16px;
  font-weight: bold; /* Make the text bold */
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.w3-button.button i {
  margin-right: 8px; /* Space between icon and text */
  color: var(--button-text-color); /* Ensure icon color matches text */
}

.w3-button.button:hover {
  background-color: var(
    --button-hover-color
  ); /* Darker background color on hover */
  color: var(--text-color); /* Text color on hover */
}

.w3-button.button:hover i {
  color: var(--text-color); /* Ensure icon color changes on hover */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .pagecontainer {
    padding: 0 10px; /* Padding for smaller screens */
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    gap: 10px; /* Space between the buttons */
  }

  .w3-button.button {
    padding: 12px 15px; /* Adjust padding for smaller buttons on mobile */
    font-size: 14px; /* Slightly reduce button text size */
  }
}
