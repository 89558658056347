.image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.image-wrapper {
  display: flex; /* Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  width: 100%;
  height: 100%;
}

.image-slide {
  display: flex; /* Flexbox to ensure the image is centered */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 100%;
}

.image {
  display: block; /* Ensure no inline spacing issues */
  margin: auto; /* Center image inside its container */
  max-width: 100%; /* Scale image proportionally */
  max-height: 100%; /* Prevent overflow */
}

.selection-buttons-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50px;
}

.selection-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.grid-lines {
  border: 1px solid red; /* Change color as needed */
}

.video-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-player-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.minimap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w3-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999; /* Make sure it's on top */
}

.w3-modal-content-custom {
  display: block;
  margin: 0 auto;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  overflow: hidden;
  padding: 0;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  position: fixed; /* Make it cover the entire screen */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* Make sure it's on top */
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: auto;
  object-fit: contain;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pronounce-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
}

.pronounce-button:hover {
  color: #007bff; /* Example hover color */
}
