.navbar {
  position: fixed;
  background-color: var(--accent-color);
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;
  padding: 0;
  z-index: 9999;
}

.spacerMain {
  height: 60px;
  margin-left: 60px;
  width: +25%;
}

.navbar-title {
  font-size: 24px;
  padding: 10px 20px;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 992px) {
  .navbar-menu-button {
    display: none;
  }
}

.navbar-menu-button:hover {
  color: var(--foreground-color);
}

.sidebar {
  color: var(--foreground-color);
  background-color: var(--accent-color);
  margin-top: 60px;
  margin-right: 200px;
  z-index: 9998;
}

.sidebar.open {
  left: 0;
}

.sidebar-item {
  padding: 15px 20px;
  color: var(--text-color);
  text-decoration: none;
  transition: background 0.3s;
}

.sidebar-item:hover {
  background-color: var(--foreground-color);
}

/* Base styles */
.enhanced-menu-button {
  background-color: #55286f; /* Accent color for visibility */
  color: #ffffff; /* Contrasting text color */
  border: none; /* Remove default borders */
  border-radius: 5px; /* Add a rounded appearance */
  padding: 10px 15px; /* Increase padding for larger click area */
  font-size: 18px; /* Make icon/text larger */
  font-weight: bold; /* Improve text visibility */
  cursor: pointer; /* Ensure it looks clickable */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

/* Hover and active states */
.enhanced-menu-button:hover {
  background-color: #6a39a2; /* Slightly lighter accent color */
}

.enhanced-menu-button:active {
  transform: scale(0.95); /* Add a "pressed" effect */
}
